<template>
  <v-list class="faq-category-list">
    <v-list-item
      v-for="item in content"
      :key="item.id"
      class="faq-category-list-item"
    >
      <router-link
        class="faq-category-list-item-link"
        :to="{ name: 'FaqList', params: { categoryName: item.slug } }"
        v-html="item.name"
        @click="clicked"
      ></router-link>
    </v-list-item>
  </v-list>
</template>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<style lang="scss">
.faq-category-list {
  padding: 16px;
  .faq-category-list-item {
    min-height: 20px;
    padding: 0 16px 0 0;
  }
  .faq-category-list-item-link {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    color: $text-color;
    text-decoration: none;
    &.router-link-active {
      font-weight: bold;
    }
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "FaqCategory",
  data() {
    return {
      content: null
    };
  },
  mixins: [clickHandler],
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      let res = await CMSService.getCustomPostByFilters("categoria_faq");
      this.content = res.data;
    }
  }
};
</script>
